import axios, { AxiosRequestConfig } from 'axios';
// config
import { HOST_API } from 'src/config-global';
import {
  STORAGE_KEY,
  resetTenantSession,
  setSession,
  setTenantSession,
} from '../auth/context/jwt/utils';
import { paths } from '../routes/paths';

// ----------------------------------------------------------------------

const managementInstance = () => {
  const axiosInstance = axios.create({ baseURL: HOST_API });

  axiosInstance.interceptors.request.use(
    async (config: any) => {
      const accessToken = localStorage.getItem(STORAGE_KEY.USER_ACCESS_TOKEN);

      if (accessToken) {
        config.headers = {
          ...config.headers,
          authorization: `Bearer ${accessToken}`,
        };
      }

      return config;
    },
    (error) => Promise.reject(error)
  );

  axiosInstance.interceptors.response.use(
    (res) => res,
    async (error) => {
      const ogConfig = error.config;
      const refresh = localStorage.getItem(STORAGE_KEY.USER_REFRESH_TOKEN);

      if (error.response.status === 403 && !ogConfig._retry) {
        ogConfig._retry = true;

        try {
          const result = await axios.get(`${HOST_API}${endpoints.auth.refreshToken}`, {
            headers: { Authorization: `Bearer ${refresh}` },
          });

          const { data } = result.data;
          if (data.accessToken && data.refreshToken) {
            setSession(data.accessToken, data.refreshToken);
          }

          return await axiosInstance(ogConfig);
        } catch (e) {
          setSession(null);

          window.location.href = paths.page403;

          return e;
        }
      }
      return Promise.reject(error?.response?.data);
    }
  );

  return axiosInstance;
};

const businessTenantInstance = () => {
  const axiosInstance = axios.create({ baseURL: HOST_API });

  axiosInstance.interceptors.request.use(
    async (config: any) => {
      const accessToken = localStorage.getItem(STORAGE_KEY.TENANT_ACCESS_TOKEN);

      if (accessToken) {
        config.headers = {
          ...config.headers,
          authorization: `Bearer ${accessToken}`,
        };
      }

      return config;
    },
    (error) => Promise.reject(error)
  );

  axiosInstance.interceptors.response.use(
    (res) => res,
    async (error) => {
      const ogConfig = error.config;
      const refresh = localStorage.getItem(STORAGE_KEY.TENANT_REFRESH_TOKEN);

      if (error.response.status === 403 && !ogConfig._retry) {
        ogConfig._retry = true;

        try {
          const result = await axios.get(`${HOST_API}${endpoints.business.refreshToken}`, {
            headers: { Authorization: `Bearer ${refresh}` },
          });

          const { data } = result.data;
          if (data.accessToken && data.refreshToken) {
            setTenantSession(data.accessToken, data.refreshToken);
          }

          return await axiosInstance(ogConfig);
        } catch (e) {
          resetTenantSession();

          window.location.href = paths.dashboard.business.root;

          return e;
        }
      }
      return Promise.reject(error?.response?.data);
    }
  );

  return axiosInstance;
};
export default {
  managementInstance: managementInstance(),
  businessTenantInstance: businessTenantInstance(),
};

// ----------------------------------------------------------------------

const managementFetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await managementInstance().get(url, { ...config });

  return res.data;
};

const businessTenantFetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await businessTenantInstance().get(url, { ...config });

  return res.data;
};

export const fetcher = {
  managementFetcher,
  businessTenantFetcher,
};
// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/management/user/me',
    login: '/management/user/sign-in',
    register: '/management/user/sign-up',
    confirmRegister: '/management/user/confirmation/email',
    refreshToken: '/management/user/session/refresh',
    requestSignUp: '/management/user/sign-up-request',
    requestSignIn: '/management/user/sign-in-request',
  },
  business: {
    me: '/management/business/me',
    register: '/management/business/registration',
    tenantSession: '/management/user/generate-tenant-session',
    tenantMe: '/management/user/business/me',
    refreshToken: '/management/user/session/refresh/business-tenant',
    update: '/management/business/update',
    remove: '/management/business/remove',
    info: '/management/business/info',
    requestRegister: '/management/business/request-registration',
    requestUpdate: '/management/business/request-update',
  },
  branch: {
    register: '/management/branch/registration',
    list: '/management/branch/business',
    details: '/management/branch/info',
    generateSelfOrderingLink: '/self-ordering/generate-qr/branch',
    requestRegister: '/management/branch/request-registration',
  },
  branchItem: {
    create: '/management/branch/item/new',
    imageUrl: '/management/branch/item/new/image',
    list: '/management/branch/item',
    details: '/management/branch/item/info',
    cloneItem: '/management/branch/item/extend/by-business',
    cloneSelectItem: '/management/branch/item/extend/by-items',
    remove: '/management/branch/item/remove',
    update: '/management/branch/item/update',
    setAvailability: '/management/branch/item/set-availability',
    syncItem: '/management/branch/item/sync',
    sortPosition: '/management/branch/item/set-sort-position',
  },
  branchSettings: {
    setPaymentCompleteMessage: '/management/branch/settings/payment-complete-message',
    settingInfo: '/branch/settings/info/branch',
  },
  pos: {
    signIn: '/pos/device/sign-in/accept',
    list: '/pos/device/business',
    get: '/pos/device/info',
    reSignIn: '/pos/device/sign-in/re-accept',
    branch: '/pos/device/branch',
    edit: '/pos/device/edit',
  },
  businessItem: {
    list: '/management/business/item',
    create: '/management/business/item/new',
    imageUrl: '/management/business/item/new/image',
    details: '/management/business/item/info',
    remove: '/management/business/item/remove',
    update: '/management/business/item/update',
  },
  category: {
    list: '/management/item/category/business',
    create: '/management/item/category/new',
    update: '/management/item/category/update',
    remove: '/management/item/category/remove',
    details: '/management/item/category/info',
    sortPosition: '/management/item/category/set-sort-position',
  },
  country: {
    list: '/management/country/business',
    support: '/supported-countries',
    create: '/management/country/new',
    details: '/management/country/info',
    remove: '/management/country/remove',
    update: '/management/country/update',
  },
  region: {
    list: '/management/region/business',
    create: '/management/region/new',
    details: '/management/region/info',
    remove: '/management/region/remove',
    update: '/management/region/update',
  },
  report: {
    branch: '/management/merchant/sale-summary/branch',
    business: '/management/merchant/sale-summary/business',
  },
  location: {
    suggest: '/suggest',
    retrieve: '/retrieve',
  },
};
